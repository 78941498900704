<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-print-12">
                    <div class="col-print-8">
                        <img width="150px" src="https://api.indoboxasia.com/images/inba_logo.png">
                    </div>
                    <div class="col-print-4">
                        <h4>CARGO MANIFEST</h4>
                    </div>
                </div>
            </div>
            <table class="table">
                <tbody>
                    <tr>

                        <td colspan="2" style="padding: 20px 0 0 0;">
                            <strong>SHIPPER NAME &amp; ADDRESS</strong><br>
                            <h4>IndoBox Asia</h4>
                            +65 6679 5757<br>
                            32 Kallang Pudding Road
                            # 01-01, Elite Industrial Building I,
                            Singapore 349313<br>

                            <br>
                            <hr>

                            <strong>Consignee Information</strong><br>
                            <h5>{{ consigne.name}}</h5>
                            <span>{{ consigne.address }}  {{ consigne.city }} {{ consigne.state }} {{ consigne.zipcode }}, {{ consigne.country }}</span>
                        </td>

                        <td width="60%">
                            <table class="table table-bordered" style="margin-bottom:0;">
                                <tbody>
                                    <tr>
                                        <td> AWB Number <br>
                                            <barcode v-bind:value="container.airwaybill" height="40" width="1"
                                                displayValue="false">
                                                Barcode not rendered
                                            </barcode>
                                            
                                            <!-- <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAbAAAABQAQMAAABCs7JfAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAE5JREFUWIXty7EJACAMRNGAvfOkczUn0BWFLGFljIhLyG/C5bgnIxdban16VW9lZI038mskgq1zvc/U9O4FBoPBYDAYDAaDwWAwGOxXtgG7GVrnpjbbEAAAAABJRU5ErkJggg=="> -->
                                            <h4> {{ container.airwaybill }}</h4>
                                        </td>
                                        <td> Type<br>
                                            <h1> {{ shipmenttype }} </h1>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> Origin/Destination<br>
                                            <h4>{{ container.origin }} / {{ container.destination }} </h4>
                                        </td>
                                        <td> Departure Date<br>
                                            <h4>{{ container.departuredate }}</h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> Arrival Date<br>
                                            <h4>{{ container.arrivaldate }}</h4>
                                        </td>
                                        <td> Authorized Agent<br>
                                            <h4> {{ container.authorizedname }}</h4>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-bordered">
                <tbody>
                    <tr>
                        <th> Tracking No</th>
                        <th> Sender</th>
                        <th> Receiver</th>
                        <th width="400px"> Products
                        </th>
                        <th> Value </th>
                        <th> Weight
                        </th>
                    </tr>
                    <tr v-for="item in containerShipments" :key="item">
                        <td>{{ item.tracking_no }}</td>
                        <td>{{ item.rname +" / "+ item.rphone +" / "+ item.remail +" / " }} <br>
                            {{ item.raddress +" "+ item.rcity +", "+ item.rstate +" "+ item.rzipcode +" "+ item.origin}}
                        </td>
                        <td>{{ item.oname +" / "+ item.ophone +" / "+ item.oemail +" / " }} <br>
                            {{ item.oaddress +" "+ item.ocity +", "+ item.ostate +" "+ item.ozipcode +" "+ item.destination }}
                        </td>
                        <td>{{ item.product + ' x ' + item.quantity }}</td>
                        <td>{{ item.currency + ' ' + item.totalprice }}</td>
                        <td>{{ item.total_weight }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style>
.v-application--wrap{
    background-color: white !important;
    margin: 2rem 4rem 2rem 4rem !important;
}
.col-print-1 {
    width: 8%;
    float: left;
    padding: 10px;
}

.col-print-2 {
    width: 16%;
    float: left;
    padding: 10px;
}

.col-print-3 {
    width: 25%;
    float: left;
    padding: 10px;
}

.col-print-4 {
    width: 33%;
    float: left;
    padding: 10px;
}

.col-print-5 {
    width: 42%;
    float: left;
    padding: 10px;
}

.col-print-6 {
    width: 50%;
    float: left;
    padding: 10px;
}

.col-print-7 {
    width: 58%;
    float: left;
    padding: 10px;
}

.col-print-8 {
    width: 66%;
    float: left;
    padding: 10px;
}

.col-print-9 {
    width: 75%;
    float: left;
    padding: 10px;
}

.col-print-10 {
    width: 83%;
    float: left;
    padding: 10px;
}

.col-print-11 {
    width: 92%;
    float: left;
    padding: 10px;
}

.col-print-12 {
    width: 100%;
    padding: 10px;
}

body {
    font-size: 13px;
}
</style>
<style type="text/css" media="print">
  @page { size: landscape; }
</style>

  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"/>

<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script><script>
import {
    mapState
} from "vuex";
import VueBarcode from 'vue-barcode';
import {
    SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
// import singaporePostalCode from "@/assets/data/singaporePostalCode.json";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
// import QrcodeVue from 'qrcode.vue'
export default {
    data() {
        return {
            id: this.$route.params.id,
            value: "TEST",
            size:200,
            shipmentType: "",
            container: {
                airwaybill: "",
                origin: "",
                destination: "",
                departuredate: new Date(),
                arrivaldate: new Date(),
                authorizedname: "",
                flightno: "",
                additionalinformation: "",
                typeid: 1,
                is_active: true,
            },
            consigne: null,
            containerShipments: [],
            containerShipmentsCount: 0,
            containerType: [{
                    id: 1,
                    name: "Air",
                },
                {
                    id: 2,
                    name: "Truck",
                },
            ],

        };
    },
    mounted() {
        this.getContainer(this.id);
        this.getContainerShipment(this.id);

        setTimeout(() => {
            window.print();
      }, 2000);
        
    },
    components: {
        Loading,
         'barcode': VueBarcode
    },
    methods: {
        getContainer(id) {
            ApiService.get("container", id)
                .then(({
                    data
                }) => {
                    this.container = data.container;
                    this.consigne = data.consigne;
                    if (this.container.typeid == 1) {
                        this.shipmenttype = "Air";
                    }
                    if (this.container.typeid == 2) {
                        this.shipmenttype = "Truck";
                    }
                })
                .catch(() => {});
        },
        getContainerShipment(id) {
            ApiService.get("getcontainershipments", id)
                .then(({
                    data
                }) => {
                    this.containerShipments = data;
                    this.containerShipmentsCount = data.length;
                })
                .catch(() => {});
        }
    },
};
</script>
